import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { APP_SNACKBAR } from 'actions/Types';
import SnackBar from '../Base';
import { globalDispatch } from '../../../store';

const SnackBarView = () => {
  const { open = false, config = {} } = useSelector((state) => state.snackbar);

  const onClose = () => {
    globalDispatch({
      type: APP_SNACKBAR,
      payload: { open: false },
    });
  };

  return (
    <SnackBar
      message={config?.message || ''}
      vertical={config?.vertical}
      horizontal={config?.horizontal}
      open={open}
      variant={config?.snackBarType || 'error'}
      autoHideDuration={config?.autoHideDuration || 5000}
      onClose={onClose}
    />
  );
};

SnackBarView.propTypes = {
  message: PropTypes.string,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  variant: PropTypes.string,
};

export default SnackBarView;
