import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppLoadingDialog from 'components/AppLoadingDialog';
import { SnackBarView } from 'components/SnackBars';

export const RouterContext = React.createContext({});

const BrowserRouter = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <AppLoadingDialog title={t('loading_single')} />
      <SnackBarView />
      <Router>
        <Route>
          {(routeProps) => (
            <RouterContext.Provider value={routeProps}>
              {children}
            </RouterContext.Provider>
          )}
        </Route>
      </Router>
    </>
  );
};

BrowserRouter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BrowserRouter;
