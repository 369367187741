import { mapKeys } from 'lodash';

import {
  FETCHING_REVENUE_CATEGORIES,
  FETCH_REVENUE_CATEGORIES_FAILED,
  FETCH_REVENUE_CATEGORIES_SUCCESS,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
} from 'actions/Types';

import { transformCategoriesJSONintoChristmasTree } from 'helpers/utils';

const INITIAL_STATE = {
  loading: false,
  error: null,
  timestamp: null, // not used for now
  data: null,
  tree: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_REVENUE_CATEGORIES:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_REVENUE_CATEGORIES_SUCCESS: {
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      const { categories } = action.payload;
      // here or in the action: I need more research on the subject
      const tree = transformCategoriesJSONintoChristmasTree(categories);
      const data = mapKeys(categories, '_id');
      return {
        ...state,
        loading: false,
        error: null,
        data,
        tree,
        timestamp: Date.now(),
      };
    }

    case FETCH_REVENUE_CATEGORIES_FAILED:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere
      // Since it failed, we don't have items to display anymore, so set it empty.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: null,
        tree: null,
        timestamp: null,
      };

    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return INITIAL_STATE;

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
};
