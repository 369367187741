import { Invoice as Rights } from '../../config/Rights';

import { Invoice as Feature } from '../../config/Features';

import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from '../../helpers/RoleHelper';

export const userCanCreateInvoice = () => {
  return hasRequestedAccess(Rights.InvoiceCreate);
};

export const userCanReadInvoice = () => {
  return hasRequestedAccess(Rights.InvoiceRead);
};

export const userCanUpdateInvoice = () => {
  return hasRequestedAccess(Rights.InvoiceUpdate);
};

export const userCanCancelInvoice = () => {
  return hasRequestedAccess(Rights.InvoiceCancel);
};

export const userCanValidateInvoice = () => {
  return hasRequestedAccess(Rights.InvoiceValidate);
};

export const userCanResendInvoice = () => {
  return hasRequestedAccess(Rights.InvoiceValidate);
};

// User has create invoice permission = user has delete permission
export const userCanDeleteInvoice = () => {
  return hasRequestedAccess(Rights.InvoiceCreate);
};

export const userOnlyReadInvoices = () => {
  const actions = [
    Rights.InvoiceCreate,
    Rights.InvoiceCancel,
    Rights.InvoiceUpdate,
    Rights.InvoiceValidate,
  ];
  const actionsAsAccess = actions.some((action) => hasRequestedAccess(action));
  return !actionsAsAccess;
};

export const userHasInvoiceAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
