import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  containerRoot: {
    backgroundColor: 'white',
    padding: '0px',
  },
  root: {
    margin: 0,
    backgroundColor: 'white',
  },
  container: {
    margin: 0,
  },
  item: {
    padding: '10px',
  },
  // this key is specifically for the grid item which contains the body
  mainBodyRoot: {
    padding: '14px',
    borderRadius: '4px',
    // overflowY: 'scroll',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.2)',
  },
  sidebarLeft: {
    padding: '0px !important',
    margin: '0px !important',
  },
  sidebarRight: {
    paddingRight: '0px',
    padding: '8px',
    margin: '0px',
  },
  sidebarTop: {
    padding: '14px',
    borderRadius: '4px',
    // overflowY: 'scroll',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.2)',
    backgroundColor: 'white',
  },
  positionSticky: {
    position: 'sticky',
    top: '-8px',
    zIndex: 2,
  },
}));
export default useStyles;
