import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useMediaQuery, Icon, Tooltip, useTheme } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import SvgIcon from 'components/AppIcons/SvgIcon';
import style from '../../assets/jss/components/appSideButton';
import '../../assets/css/icons.css';

const AppSidebarButton = ({
  text,
  disabled,
  type,
  classes,
  onClick,
  pageDisplayed,
  iconMUI,
  iconSVG,
  onlyIcon = false,
}) => {
  const theme = useTheme();
  const { isCollapse } = useSelector((state) => state.resizeScreen);
  const smallscreen = !useMediaQuery('(min-width:1024px)');
  const btnClasses = classNames({
    [classes.button]: true,
    [classes.disabled]: disabled,
    [classes.pageDisplayed]: pageDisplayed,
  });

  const getIconColor = () => {
    let iconColor = 'white';
    if (disabled) {
      iconColor = 'rgba(255,255,255,0.2)';
    } else if (pageDisplayed) {
      iconColor = theme.palette.primary.main;
    }
    return iconColor;
  };

  return (
    <Tooltip
      title={isCollapse ? text.toUpperCase() : ''}
      placement="right-start"
    >
      <button
        type={type}
        className={btnClasses}
        onClick={onClick}
        disabled={disabled}
      >
        {smallscreen || isCollapse || onlyIcon ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {iconMUI && <Icon component={iconMUI} fontSize="medium" />}
            {iconSVG && (
              <SvgIcon
                path={iconSVG}
                color={getIconColor()}
                width={32}
                height={32}
              />
            )}
          </div>
        ) : (
          <p className={classes.buttonText}>{text}</p>
        )}
      </button>
    </Tooltip>
  );
};

AppSidebarButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  pageDisplayed: PropTypes.bool,
  type: PropTypes.string,
  iconName: PropTypes.string,
  iconMUI: PropTypes.object,
  iconSVG: PropTypes.string,
  onlyIcon: PropTypes.bool,
};
AppSidebarButton.defaultProps = {
  disabled: false,
  onClick: null,
  iconName: '',
  pageDisplayed: false,
};

export default withStyles(style)(AppSidebarButton);
