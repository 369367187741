import {
  Purchase as Rights,
  Dashboard as DashboardRights,
} from '../../config/Rights';
import { Purchase as Feature } from '../../config/Features';

import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from '../../helpers/RoleHelper';

export const userCanCreatePurchase = () => {
  return hasRequestedAccess(Rights.PurchaseCreate);
};

export const userCanReadPurchase = () => {
  return hasRequestedAccess(Rights.PurchaseRead);
};

export const userCanUpdatePurchase = () => {
  return hasRequestedAccess(Rights.PurchaseUpdate);
};

export const userCanDeletePurchase = () => {
  return hasRequestedAccess(Rights.PurchaseDelete);
};

export const userCanValidatePurchase = () => {
  return hasRequestedAccess(Rights.PurchaseValidate);
};

export const userCanOverviewPurchase = () => {
  return hasRequestedAccess(DashboardRights.ExpenseOverview);
};

export const userHasPurchaseAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
