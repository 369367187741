import {
  contentText,
  titleDangerText,
  smallTitleText,
} from 'assets/jss/components/appText';
import CustomDialog from 'components/CustomDialog';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppCheckbox from 'components/AppCheckbox';
import { Box, styled } from '@material-ui/core';
import AppButton from 'components/AppButton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { fetchCompanySubscription } from 'actions/CompanySubscription';

import { useThunkDispatch } from 'hooks/generalHooks';
import { useSelector } from 'react-redux';
import spinnerService from 'services/SpinnerService';
import formatUTCDate from 'helpers/dateHelper';
import { TIME_FORMAT } from 'constants/date';
import subscriptionService from 'services/SubscriptionService';
import logger from 'helpers/logger';
import colors from '../../constants/colors.json';

const BoxButton = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px',
  gap: '20px',
}));

const PricingUpdateDialog = ({ open, onContinue }) => {
  const [checked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const companySubscription = useSelector((state) => state.companySubscription);
  const { loading, data } = companySubscription;

  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const history = useHistory();

  const validation = () => {
    if (!checked) {
      setErrorMessage(t('pricing_update_dialog.notice_message'));
      throw new Error(t('pricing_update_dialog.notice_message'));
    }
  };

  const handleCloseCompany = async () => {
    try {
      validation();
      spinnerService.startSpinner();
      await subscriptionService.cancelSubscriptions();
      history.push('/logout');
    } catch (error) {
      logger.log({ error });
    } finally {
      spinnerService.endSpinner();
    }
  };

  const handleContinueUse = async () => {
    try {
      validation();
      spinnerService.startSpinner();
      await subscriptionService.markMigrated();
      onContinue();
    } catch (error) {
      logger.log({ error });
    } finally {
      spinnerService.endSpinner();
    }
  };

  useEffect(() => {
    if (open) {
      spinnerService.executePromises(dispatch(fetchCompanySubscription()));
    }
  }, [open]);

  return (
    <>
      {open && !loading && data && (
        <CustomDialog>
          <p
            style={{
              ...titleDangerText,
              textAlign: 'left',
              marginBottom: '40px',
            }}
          >
            {t('pricing_update_dialog.title')}
          </p>

          <p
            style={contentText}
            dangerouslySetInnerHTML={{
              __html: t('pricing_update_dialog.content', {
                trialStart: data?.trialStartDate
                  ? formatUTCDate(data.trialStartDate, TIME_FORMAT.D_M_Y)
                  : '',
              }),
            }}
          ></p>
          <AppCheckbox
            checked={checked}
            style={{ marginLeft: '-12px' }}
            labelText={t('pricing_update_dialog.checkbox_label')}
            color="primary"
            onClick={(e) => {
              if (e.target.checked) {
                setErrorMessage('');
              } else {
                setErrorMessage(t('pricing_update_dialog.notice_message'));
              }

              setChecked(e.target.checked);
            }}
          />
          {errorMessage && (
            <p style={{ ...smallTitleText, color: colors.error }}>
              {errorMessage}
            </p>
          )}

          <BoxButton>
            <AppButton
              onClick={handleCloseCompany}
              type="submit"
              text={t('pricing_update_dialog.cancel_button')}
              color="redLight"
              noBorder={true}
              style={{ fontSize: '12px' }}
            />
            <AppButton
              onClick={handleContinueUse}
              type="submit"
              text={t('pricing_update_dialog.continue_button')}
              color="primary"
              noBorder={true}
              style={{ fontSize: '12px' }}
            />
          </BoxButton>
        </CustomDialog>
      )}
    </>
  );
};

PricingUpdateDialog.propTypes = {
  open: PropTypes.bool,
  onContinue: PropTypes.func,
};

export default PricingUpdateDialog;
