export const TYPE_SOCIETE = 'Société';
export const TYPE_ENTREPRENEUR_INDIVIDUEL = 'Entrepreneur individuel';
export const TYPE_AUTO_ENTREPRENEUR = 'Auto-entrepreneur';
export const PAYMENTS_METHODS_KEYS = {
  BRIDGE_PAYMENT_LINK: 'bridgePaymentLink',
  HI_PAY: 'hiPay',
  PAYPAL: 'paypal',
  CASH: 'cash',
  CHECK: 'check',
  BANK_TRANSFER: 'bankTransfer',
  LCR: 'lcr',
};

export const COMPANY_TYPE_OPTIONS = {
  [TYPE_AUTO_ENTREPRENEUR]: {
    title: 'une micro entreprise ?',
    description:
      "Il s'agit d'un statut de l'entreprise individuelle. Elle permet de lancer son activité rapidement, grâce à un processus de création simple, et une gestion quotidienne également simplifiée. Attention cependant à ne pas dépasser les seuils selon l'activité exercée. ",
    button: 'Je suis bien en micro-entreprise',
  },
  [TYPE_ENTREPRENEUR_INDIVIDUEL]: {
    title: 'une entreprise individuelle ?',
    description:
      "Il s'agit d'une entreprise où son identité correspond à celle de son dirigeant. La création est simplifiée et le chiffre d'affaires est à déclarer via la déclaration de revenu du dirigeant. ATTENTION, la responsabilité du dirigeant est engagée sur l'ensemble de ses biens personnels. ",
    button: 'Je suis bien en entreprise individuelle ',
  },
  [TYPE_SOCIETE]: {
    title: 'une société ?',
    description:
      "Une société est une personnalité morale (différente de l'identité de son dirigeant). Les biens personnels du/des dirigeants sont protégés. Il faut un KBIS (carte d'identité de la société) lorsque vous souhaitez exercer en SAS, SASU, SARL, EURL et en SCI. ",
    button: 'Je suis bien en société ',
  },
};

export const COMPANY_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending',
};

export const COMPANY_TYPE = {
  ADMIN: 'admin',
  COMPANY: 'company',
};

export const COMPANIES_RENEW = 'companiesRenew';
