import { Subscription as Rights } from '../../config/Rights';
import { Subscription } from '../../config/Features';

import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from '../../helpers/RoleHelper';

export const userCanReadSubscription = () => {
  return hasRequestedAccess(Rights.SubscriptionRead);
};

export const userCanUpdateSubscription = () => {
  return hasRequestedAccess(Rights.SubscriptionUpdate);
};

export const userHasSubscriptionAccessFeature = () => {
  return hasRequestedAccessFeature(Subscription);
};
