import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormControl, Button, Box } from '@material-ui/core';
import {
  COMPANIES_RENEW,
  COMPANY_STATUS,
  COMPANY_TYPE,
} from 'constants/company';
import logger from 'helpers/logger';
import localStorageHelper from 'helpers/localStorageHelper';
import PricingUpdateDialog from 'components/PricingUpdateDialog';
import AppRadioGroup from '../AppRadioGroup';
import AppRadioButton from '../AppRadioButton';
import AppButton from '../AppButton';
import CustomDialog from '../CustomDialog';
import LoadingDialog from '../LoadingDialog';
import styles from '../../assets/jss/root';
import { titleText } from '../../assets/jss/components/appText';

import state from './state';
import dispatch from './dispatch';

const ChooseCompany = (props) => {
  const {
    loggedUserCompany: { companies, company },
    history,
    closeDialog,
    fetchCompanyUserData,
    t,
    selectedCompany,
    selectedCompanyId,
    userLoggedIn,
    classes,
    fetchLoggedUserCompanies,
  } = props;
  const [showCompanyDialog, setShowCompanyDialog] = useState(true);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [fetchCompaniesData, setFetchCompaniesData] = useState(false);
  const [fetchCompanyData, setFetchCompanyData] = useState(false);

  const isCompanyDisabled = (companyData) => {
    return (
      (companyData?.status === COMPANY_STATUS.INACTIVE ||
        companyData?.status === COMPANY_STATUS.PENDING) &&
      companyData.type !== COMPANY_TYPE.ADMIN
    );
  };

  const [value, setValue] = useState();
  const [isSubscriptionMigrated, setIsSubscriptionMigrated] = useState(
    companies.length === 1 ? companies[0].isSubscriptionMigrated : false
  );

  const companyRenews = localStorageHelper.getItem(COMPANIES_RENEW) || {};

  const onDataFetched = () => {
    setFetchCompanyData(false);
    if (closeDialog) {
      closeDialog();
    }
    const url = window.location.href;
    const domain = window.location.hostname;
    const expr = `(http|https)://${domain}(:[0-9]+)?`;
    const rx = new RegExp(expr, 'g');
    history.push(url.replace(rx, ''));
  };

  const handleFetchCompanyData = () => {
    logger.debug('ChooseCompany triggers fetchCompanyUserData()');
    fetchCompanyUserData(onDataFetched);
  };

  const selectFirstCompany = (selectedCompanyIndex) => {
    if (selectedCompanyIndex !== -1) {
      setFetchCompanyData(true);
      selectedCompany(selectedCompanyIndex);
      handleFetchCompanyData();
    }
  };

  const fetchCompanyLoading = useMemo(() => {
    const title = `${t('loading')} ${
      company ? company?.name : companies[0]?.name
    }`;
    return <LoadingDialog title={title} />;
  }, [company, companies]);

  const fetchCompaniesLoading = useMemo(() => {
    const title = `${t('loading_companies')}`;
    return <LoadingDialog title={title} />;
  }, []);

  const onSubmit = () => {
    selectedCompany(value);
    setFetchCompanyData(true);
    handleFetchCompanyData();
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    setIsSubscriptionMigrated(
      companies[+event.target.value]?.isSubscriptionMigrated || false
    );
  };

  const clickChooseCompany = () => {
    if (isSubscriptionMigrated) {
      setShowCompanyDialog(false);
      setShowConfirmDialog(true);
      selectedCompanyId(companies[+value]._id);
    } else {
      onSubmit();
    }
  };

  useEffect(() => {
    setFetchCompaniesData(true);
    fetchLoggedUserCompanies(() => {
      setFetchCompaniesData(false);
    });
  }, []);

  useEffect(() => {
    if (!companies?.length) {
      return;
    }
    const selectedCompanyIndex = companies.findIndex((item) =>
      company ? item._id === company._id : !isCompanyDisabled(item)
    );
    setValue(selectedCompanyIndex);
    if (
      !company &&
      companies.length === 1 &&
      !isCompanyDisabled(companies[0])
    ) {
      selectFirstCompany(selectedCompanyIndex);
    }
  }, [companies, company]);

  if (fetchCompanyData) return fetchCompanyLoading;

  if (fetchCompaniesData) return fetchCompaniesLoading;

  if (!companies.length) {
    return (
      <CustomDialog
        title={t('no_company')}
        contentText={t('you_have_no_company')}
      >
        <Button
          onClick={() => {
            window.location.href = '/logout';
          }}
          variant="contained"
          className={classes.button}
          fullWidth={true}
        >
          OK
        </Button>
      </CustomDialog>
    );
  }

  return (
    <>
      {showCompanyDialog && (
        <CustomDialog closeDialog={closeDialog} userLoggedIn={userLoggedIn}>
          <FormControl
            component="fieldset"
            style={{
              width: '50%',
              margin: '0 25%',
            }}
          >
            <p style={titleText}>{t('please_choose_the_company')}</p>

            <AppRadioGroup
              color="secondary"
              name="gender1"
              valueSelected={`${value}`}
              onChange={handleChange}
            >
              {companies.map((item, index) => (
                <Box
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <AppRadioButton
                    value={`${index}`}
                    text={item.name}
                    disabled={isCompanyDisabled(item)}
                  />
                  {isCompanyDisabled(item) && !companyRenews[item._id] && (
                    <a
                      style={{ textDecoration: 'underline' }}
                      target="_blank"
                      rel="noreferrer"
                      href={
                        item?.status === COMPANY_STATUS.PENDING
                          ? `/select-subscription/${item._id}`
                          : `${process.env.REACT_APP_API_URL_NO_VERSION}/subscription/checkout-session?companyId=${item._id}`
                      }
                    >
                      {item?.status === COMPANY_STATUS.PENDING
                        ? t('subscription.select_subscription')
                        : t('subscription.renew_subscription')}
                    </a>
                  )}
                </Box>
              ))}
            </AppRadioGroup>
            <AppButton
              onClick={clickChooseCompany}
              type="submit"
              fullWidth={true}
              text="OK"
              color="secondaryLight"
              noBorder={true}
            />
          </FormControl>
        </CustomDialog>
      )}
      <PricingUpdateDialog open={showConfirmDialog} onContinue={onSubmit} />
    </>
  );
};

ChooseCompany.propTypes = {
  classes: PropTypes.object.isRequired,
  companies: PropTypes.array,
  t: PropTypes.func,
  closeDialog: PropTypes.func,
  userLoggedIn: PropTypes.bool,
  loggedUser: PropTypes.object,
  loggedUserCompany: PropTypes.object,
  isAuthenticated: PropTypes.func.isRequired,
  selectedCompany: PropTypes.func,
  history: PropTypes.object,
  fetchCompanyUserData: PropTypes.func,
  fetchLoggedUserCompanies: PropTypes.func,
  selectedCompanyId: PropTypes.func,
  dispatch: PropTypes.func,
};

const TranslatedChooseCompany = withTranslation()(ChooseCompany);
const ChooseCompanyWithStyle = withStyles(styles, { withTheme: true })(
  TranslatedChooseCompany
);
const ChooseCompanyWithRouter = withRouter(ChooseCompanyWithStyle);

export default connect(state, dispatch)(ChooseCompanyWithRouter);
