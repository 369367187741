import { Providers as Rights } from '../../config/Rights';
import { Providers as Feature } from '../../config/Features';
import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from '../../helpers/RoleHelper';

export const userCanCreateProvider = () => {
  return hasRequestedAccess(Rights.ProvidersCreate);
};

export const userCanUpdateProvider = () => {
  return hasRequestedAccess(Rights.ProvidersUpdate);
};

export const userHasProviderAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
