import { REGEX } from 'constants/form';

export const ValidateRequired = (value) => {
  const _value =
    value && typeof value === 'string' ? value.trim() : String(value);
  if (!_value || !value) {
    return false;
  }
  return true;
};
export const ValidateEmail = (value) => {
  if (!value) {
    return false;
  }
  const re = /\S+@\S+\.\S+/;
  return re.test(value);
};
export const ValidatePhone = (value) => {
  if (!value) {
    return true;
  }
  const re = /^(\+33|0)[1-9](\d{2}){4}$/;
  return re.test(value);
};
export const ValidateSiret = (value) => {
  if (!value) {
    return true; // accept empty siret
  }
  const re = /^[0-9]{14}$/;
  return re.test(value);
};
export const ValidateZipCode = (value) => {
  if (!value) {
    return false;
  }
  const re = /^[0-9]{5}$/;
  return re.test(value);
};
export const ValidateIban = (value) => {
  if (!value) {
    return true;
  }
  // const re = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{11}[0-9]{2}$/;
  return REGEX.IBAN.test(value);
};

export const validateUnique = (value = '', list = [], key = '') => {
  if (!value) {
    return false;
  }
  const isUnique = !!list?.every((item) => item[key] !== value);
  return isUnique;
};

export const validateNotUndefined = (value) => value !== undefined;

export const validator = {
  required: ValidateRequired,
  email: ValidateEmail,
  phone: ValidatePhone,
  siret: ValidateSiret,
  zipCode: ValidateZipCode,
  iban: ValidateIban,
  unique: validateUnique,
  notUndefined: validateNotUndefined,
};
