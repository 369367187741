export const Expense = 'Expense';
export const Purchase = 'Purchase';
export const AdvanceExpenses = 'AdvanceExpenses';
export const Invoice = 'Invoice';
export const Template = 'Template';
export const Items = 'Items';
export const Clients = 'Clients';
export const Projects = 'Projects';
export const UserManagement = 'UserManagement';
export const UserRoleManagement = 'UserRoleManagement';
export const CompanySettings = 'CompanySettings';
export const BookKeeping = 'BookKeeping';
export const Cards = 'Cards';
export const Providers = 'Providers';
export const Activity = 'Activity';
export const Subscription = 'Subscription';
