import {
  userCanReadCard,
  userCanCreateCard,
  userCanUpdateCard,
} from 'selectors/rightsSelector/cardRequests';
import { userHasProjectAccessFeature } from 'selectors/rightsSelector/projectRequests';
import {
  userCanUpdateTemplate,
  userHasTemplateAccessFeature,
  userCanCreateTemplate,
} from '../../selectors/rightsSelector/templateRequests';

import {
  userHasExpenseAccessFeature,
  userCanCreateExpense,
  userCanUpdateExpense,
  userCanReadExpense,
} from '../../selectors/rightsSelector/expenseRequests';

import {
  userHasInvoiceAccessFeature,
  userCanReadInvoice,
  userCanCreateInvoice,
  userCanUpdateInvoice,
} from '../../selectors/rightsSelector/invoiceRequests';

import {
  userHasAdvanceExpenseAccessFeature,
  userCanReadAdvanceExpense,
  userCanCreateAdvanceExpense,
  userCanUpdateAdvanceExpense,
} from '../../selectors/rightsSelector/advanceExpenseRequests';

import { userHasItemAccessFeature } from '../../selectors/rightsSelector/itemRequests';

import { userHasUserManagementAccessFeature } from '../../selectors/rightsSelector/userManagementRequests';

import {
  userHasUserRoleManagementAccessFeature,
  userCanCreateUserRoleManagement,
  userCanUpdateUserRoleManagement,
} from '../../selectors/rightsSelector/userRoleManagementRequests';

import { userHasClientAccessFeature } from '../../selectors/rightsSelector/clientRequests';

import {
  companyDataAreMissing,
  userDataAreMissing,
} from '../../selectors/missingData';

import { userHasBookKeepingAccessFeature } from '../../selectors/rightsSelector/bookKeepingRequests';

import { userCanUpdateEmailTemplate } from '../../selectors/rightsSelector/emailTemplateRequests';
import { userHasAccessAgentFeature } from '../../selectors/rightsSelector/AgentRequest';
import {
  userCanCreatePurchase,
  userCanReadPurchase,
  userCanUpdatePurchase,
  userHasPurchaseAccessFeature,
} from '../../selectors/rightsSelector/purchaseRequest';
import { userHasCompanySettingsAccessFeature } from '../../selectors/rightsSelector/companySettingsRequests';

export default ({ loggedUser, appInitialized, ...restState }) => {
  const userHasUserManagementAccess =
    userHasUserManagementAccessFeature(restState);

  const userHasTemplateAccess = userHasTemplateAccessFeature(restState);
  const userHasUserRoleManagementAccess =
    userHasUserRoleManagementAccessFeature(restState);
  const userHasItemAccess = userHasItemAccessFeature(restState);
  const userHasClientAccess = userHasClientAccessFeature(restState);
  const userAccessEmailTemplate = userCanUpdateEmailTemplate(restState);

  const userHasProjectAccess = userHasProjectAccessFeature(restState);

  return {
    loggedUser,
    loggedUserCompany: restState.loggedUserCompany,
    companySubscription: restState.companySubscription,
    appInitialized,
    permissions: {
      mandatoryDataAreMissing:
        companyDataAreMissing(restState).length > 0 ||
        userDataAreMissing({
          loggedUser,
          loggedUserRights: restState.loggedUserRights,
        }).length > 0,

      userHasExpenseAccess: userHasExpenseAccessFeature(restState),
      userCanCreateExpense: userCanCreateExpense(restState),
      userCanUpdateExpense: userCanUpdateExpense(restState),
      userCanReadExpense: userCanReadExpense(restState),

      userHasPurchaseAccess: userHasPurchaseAccessFeature(restState),
      userCanCreatePurchase: userCanCreatePurchase(restState),
      userCanUpdatePurchase: userCanUpdatePurchase(restState),
      userCanReadPurchase: userCanReadPurchase(restState),

      userHasAdvanceExpenseAccess:
        userHasAdvanceExpenseAccessFeature(restState),
      userCanReadAdvanceExpense: userCanReadAdvanceExpense(restState),
      userCanCreateAdvanceExpense: userCanCreateAdvanceExpense(restState),
      userCanUpdateAdvanceExpense: userCanUpdateAdvanceExpense(restState),

      userHasInvoiceAccess: userHasInvoiceAccessFeature(restState),
      userCanReadInvoice: userCanReadInvoice(restState),
      userCanCreateInvoice: userCanCreateInvoice(restState),
      userCanUpdateInvoice: userCanUpdateInvoice(restState),

      userHasTemplateAccess,
      userCanCreateTemplate: userCanCreateTemplate(restState),
      userCanUpdateTemplate: userCanUpdateTemplate(restState),
      userHasCompanySettingsAccess:
        userHasCompanySettingsAccessFeature(restState),

      userHasItemAccess,
      userHasUserManagementAccess,

      userHasUserRoleManagementAccess,
      userCanCreateUserRoleManagement:
        userCanCreateUserRoleManagement(restState),
      userCanUpdateUserRoleManagement:
        userCanUpdateUserRoleManagement(restState),

      userHasClientAccess,
      userHasBookKeepingAccess: userHasBookKeepingAccessFeature(restState),

      userAccessEmailTemplate,

      userCanReadCard: userCanReadCard(restState),
      userCanCreateCard: userCanCreateCard(restState),
      userCanUpdateCard: userCanUpdateCard(restState),

      userHasAgentAccess: userHasAccessAgentFeature(restState),
      userHasProjectAccess,
    },
  };
};
