import { authInstance as axios } from '../../helpers/axiosInterceptor';

const fetchUsers = async (filter) =>
  axios.get(`${process.env.REACT_APP_USER_URL}/users`, { params: filter });

const fetchAuthUser = async () =>
  axios.get(`${process.env.REACT_APP_USER_URL}/me`);

const fetchUser = async (id) =>
  axios.get(`${process.env.REACT_APP_USER_URL}/users/${id}`);

const createUser = async (user) =>
  axios.post(`${process.env.REACT_APP_USER_URL}/users`, user);

const updateUser = async (user) =>
  axios.put(`${process.env.REACT_APP_USER_URL}/users/${user._id}`, user);

const deleteUser = async (id) =>
  axios.delete(`${process.env.REACT_APP_USER_URL}/users/${id}`);

const invite = (params) =>
  axios.post(`${process.env.REACT_APP_USER_URL}/users/invite`, params);

const getUserByEmail = (email) =>
  axios.get(`${process.env.REACT_APP_USER_URL}/users/email/${email}`);

const fetchTreezorUsers = () => {
  // Return dummy data because we don't use this function anymore
  // axios.get(`${process.env.REACT_APP_TREEZOR_URL}/users`);
  return { data: [] };
};

const buyUserQuota = async (body) => {
  const res = await axios.post(
    `${process.env.REACT_APP_STRIPE_URL}/buy-user-quota`,
    body
  );
  return res.data;
};

export default {
  fetchAuthUser,
  fetchUsers,
  fetchUser,
  createUser,
  updateUser,
  deleteUser,
  invite,
  getUserByEmail,
  fetchTreezorUsers,
  buyUserQuota,
};
