import { authInstance as axios } from '../../helpers/axiosInterceptor';

const fetchCompanies = async (filter) =>
  axios.get(`${process.env.REACT_APP_COMPANY_URL}/companies`, {
    params: filter,
  });

const fetchUserCompanies = async (filter) =>
  axios.get(`${process.env.REACT_APP_COMPANY_URL}/mycompanies`, {
    params: {
      limit: 'all',
      ...filter,
    },
  });

const fetchCompany = async (id) =>
  axios.get(`${process.env.REACT_APP_COMPANY_URL}/companies/${id}`);

const updateCompany = async (company) =>
  axios.put(
    `${process.env.REACT_APP_COMPANY_URL}/companies/${company._id}`,
    company
  );

export default {
  fetchCompanies,
  fetchCompany,
  updateCompany,
  fetchUserCompanies,
};
