import { ROLE } from 'constants/roles';
import { CompanySettings as Rights } from '../../config/Rights';

import RoleHelper, { hasRequestedAccess } from '../../helpers/RoleHelper';
import { userHasTemplateAccessFeature } from './templateRequests';
import { userHasUserRoleManagementAccessFeature } from './userRoleManagementRequests';
import { userHasItemAccessFeature } from './itemRequests';
import { userHasClientAccessFeature } from './clientRequests';
import { userCanUpdateEmailTemplate } from './emailTemplateRequests';
import { userHasActivityAccessFeature } from './activityRequest';
import { userHasSubscriptionAccessFeature } from './subscriptionRequest';
import { userHasProviderAccessFeature } from './providerRequests';
import { userHasProjectAccessFeature } from './projectRequests';
import { userHasUserManagementAccessFeature } from './userManagementRequests';

export const userCanUpdateCompanySettings = () => {
  return hasRequestedAccess(Rights.CompanySettingsUpdate);
};

export const userHasCompanySettingsAccessFeature = () => {
  const userHasUserManagementAccess = userHasUserManagementAccessFeature();
  const userCanUpdateCompany = userCanUpdateCompanySettings();
  const userHasTemplateAccess = userHasTemplateAccessFeature();
  const userHasUserRoleManagementAccess =
    userHasUserRoleManagementAccessFeature();
  const userHasItemAccess = userHasItemAccessFeature();
  const userHasClientAccess = userHasClientAccessFeature();
  const userAccessEmailTemplate = userCanUpdateEmailTemplate();
  const userHasActivityAccess = userHasActivityAccessFeature();
  const userHasSubscriptionAccess = userHasSubscriptionAccessFeature();
  const userHasProviderAccess = userHasProviderAccessFeature();
  const userHasProjectAccess = userHasProjectAccessFeature();
  const isAdmin = RoleHelper.hasRoles([ROLE.ADMINISTRATEUR]);

  const userHasAccessCompanySetting =
    userHasUserManagementAccess ||
    userHasUserRoleManagementAccess ||
    userHasItemAccess ||
    userHasClientAccess ||
    userCanUpdateCompany ||
    userHasActivityAccess ||
    userHasSubscriptionAccess ||
    userHasProviderAccess ||
    userAccessEmailTemplate ||
    userHasProjectAccess ||
    userHasTemplateAccess ||
    isAdmin;

  return userHasAccessCompanySetting;
};
