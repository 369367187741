import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';

import i18n from './i18n';
import MyRouter from './routes';
import { store, persistor } from './store';
import './assets/css/variable.css';
import theme from './assets/jss/theme';
import './assets/css/index.css'; // it would be better to define those root in the theme

// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
  (() => {
    // check domain is bybbf.com then redirect to bebusinessfocus.com

    if (window.location.hostname.includes('bybbf.com')) {
      let url = window.location.href;

      if (window.location.hostname === 'app.bybbf.com') {
        url = url.replace('bybbf.com', 'bebusinessfocus.com');
        window.location.href = url;

        return null;
      }
    }

    // dev environment
    if (window.location.hostname.includes('monamourdecomptable.com')) {
      let url = window.location.href;

      if (window.location.hostname === 'dev-app.monamourdecomptable.com') {
        url = url.replace(
          'dev-app.monamourdecomptable.com',
          'app.dev.bybbf.com'
        );
        window.location.href = url;

        return null;
      }
    }

    return (
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <MuiThemeProvider theme={theme}>
              <CssBaseline>
                <Suspense fallback={'Loading...'}>
                  <MyRouter />
                </Suspense>
              </CssBaseline>
            </MuiThemeProvider>
          </PersistGate>
        </Provider>
      </I18nextProvider>
    );
  })(),
  document.getElementById('root')
);
// registerServiceWorker();
