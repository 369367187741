import { authInstance, simpleInstance } from '../../helpers/axiosInterceptor';

const upload = async (file, folder, filename) => {
  // get post url and hashed fields
  const {
    data: { url, fields },
  } = await authInstance.get(
    `${process.env.REACT_APP_MEDIA_URL}/getUploadurl?key=${folder}`
  );

  const formData = new FormData();

  /**
   * Don't change the order of the data.
   * The first one must be the key, and the last one
   * must be the file.
   */
  formData.append('key', `${folder}/${filename}`);
  formData.append('acl', 'private');
  formData.append('content-type', file.type);
  Object.keys(fields).forEach((field) => {
    formData.append(field, fields[field]);
  });

  formData.append('file', file);

  // upload file directly to S3
  const result = await simpleInstance.post(url, formData, {
    headers: {
      'Content-Type': file.type,
    },
  });

  return result;
};

const geturl = async (filename) => {
  const {
    data: { url, type },
  } = await authInstance.get(
    `${process.env.REACT_APP_MEDIA_URL}/geturl?key=${filename}`
  );

  return { url, type };
};

const getObject = async (filename) => {
  return authInstance
    .get(`${process.env.REACT_APP_MEDIA_URL}/getobject?key=${filename}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

const getContentPdfByUrl = async (url, attempts = 3) => {
  try {
    const response = await simpleInstance.get(url, { responseType: 'blob' });
    if (response.data) return response.data;
  } catch (error) {
    if (attempts > 0) {
      const response = await getContentPdfByUrl(url, attempts - 1);
      return response;
    }
    console.log(`Error: ${error.message}`);
  }
  return null;
};

export default { upload, geturl, getContentPdfByUrl, getObject };
