import _ from 'lodash';
import XLSX, { write, utils } from 'xlsx';
import { saveAs } from 'file-saver';

import { INVOICE_TYPE } from 'helpers/invoiceHelpers';

const deepValue = (obj, path) =>
  path
    .replace(/\[|\]\.?/g, '.')
    .split('.')
    .filter((s) => s)
    .reduce((acc, val) => acc && acc[val], obj);

const groupBy = (xs, key, convertToObject = false) => {
  const arr = xs.reduce((rv, x) => {
    const val = deepValue(x, key);

    if (val !== undefined) {
      // eslint-disable-next-line
      (rv[val] = rv[val] || []).push(x);
    }
    return rv;
  }, {});

  return convertToObject ? { ...arr } : arr;
};

const makeid = (length) => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

const prettyInvoiceNumber = (value, num = 7) => {
  const expr = `\\B(?=(\\d{${num},}))`;
  const rx = new RegExp(expr, 'g');
  return value.replace(rx, ' - ');
};

const isEquivalent = (a, b) => {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i += 1) {
    const propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

const formatNum = (
  invoice,
  config = {
    invoice: 'FAC',
    credit_note: 'AVO',
    quotation: 'DEV',
    order_form: 'BDC',
    delivery_form: 'BDL',
  }
) => {
  if (!invoice.invoice_nb) {
    return 'Error';
  }

  let text;
  let number;
  switch (invoice.type) {
    case INVOICE_TYPE.INVOICE:
    case INVOICE_TYPE.PRE_PAYMENT:
      text = config.invoice;
      // because FACT
      number = invoice.invoice_nb.substring(4);
      break;
    case INVOICE_TYPE.CREDIT_NOTE:
      text = config.credit_note;
      break;
    case INVOICE_TYPE.QUOTATION:
      text = config.quotation;
      break;
    case INVOICE_TYPE.ORDER_FORM:
      text = config.order_form;
      break;
    case INVOICE_TYPE.DELIVERY_FORM:
      text = config.delivery_form;
      break;
    default:
      text = config.invoice;
  }
  if (!number) {
    number = invoice.invoice_nb.substring(text.length);
  }
  let trimmedNumber = number.replace(/^0+/, '');
  while (trimmedNumber.length < 4) {
    trimmedNumber = `0${trimmedNumber}`;
  }
  text += `${trimmedNumber}${
    invoice.state === 'draft' || invoice.state === 'pending' ? 'B' : ''
  }`;

  return text;
};

const getFileExtension = (name) =>
  name.substring(name.lastIndexOf('.') + 1, name.length);

const missingDataFromObject = (obj, key) => {
  if (!obj) {
    return [];
  }

  if (key.includes('.')) {
    return !key.split('.').reduce((p, c) => (p && p[c]) || null, obj);
  }
  return !Object.keys(obj).includes(key) || !obj[key];
};

const getObjectMissingFields = (requiredKeys, obj) =>
  requiredKeys.filter((key) => missingDataFromObject(obj, key));

const unflatten = (array, p, t) => {
  let tree = typeof t !== 'undefined' ? t : [];
  const parent = typeof p !== 'undefined' ? p : { _id: undefined };

  const children = _.filter(array, (child) => child.parent === parent._id);

  if (!_.isEmpty(children)) {
    if (typeof parent._id === 'undefined') {
      tree = _.orderBy(children, ['order'], ['asc']);
    } else {
      parent.children = _.orderBy(children, ['order'], ['asc']);
    }
    _.each(children, (child) => {
      unflatten(array, child);
    });
  }

  return tree;
};

const debounceFn = (fn, mm = 1000) => {
  return _.debounce(fn, mm, {
    leading: false,
    trailing: true,
  });
};

// TODO : add a uniq root to the tree
// const transformCategoriesJSONintoChristmasTree = arr => {
//   const root = {
//     _id: 'root',
//     children: unflatten(arr),
//   };
//   return root;
// };
// for now we keep the array
const transformCategoriesJSONintoChristmasTree = unflatten;

const getRcsFromConcatenatdString = (rcs) => {
  // This function breaks down a RCS string such as "RCS Lyon" or simply "Lyon into rcsType=RCS and rcsWithoutPrefix="Lyon"
  // We have to manage both old and new values. Old values could be simply "Lyon" New values should be more like "RCS Lyon"

  // Initialise the rcsType
  let rcsType = 'RCS';
  // Initialise rcsWithoutPrefix
  let rcsWithoutPrefix = rcs;

  // Does the rcs given to us starts with RM or RCS
  const firstPartRcs = rcs ? rcs.split(' ')[0] : null;

  if (firstPartRcs && (firstPartRcs === 'RM' || firstPartRcs === 'RCS')) {
    // update teh rcsType with the "real" value
    rcsType = firstPartRcs;
    // update the rcsWithoutPrefix with the "real" value
    /* eslint-disable-next-line prefer-destructuring */
    rcsWithoutPrefix = rcs.split(`${rcsType} `)[1]; // there may be loads of spaces, so we just split on the first part, either "RM " or "RCS "
  }

  return [rcsType, rcsWithoutPrefix];
};

const isEmpty = (str) => {
  return !str || str.length === 0;
};

const isBlank = (str) => {
  return !str || /^\s*$/.test(str);
};

const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return re.test(String(email).toLowerCase());
};

const calculatePercentage = (value, max) => Math.round((value / max) * 100);

const roundedNumber = (value) => {
  if (!_.isNaN(value) && !_.isNull(value)) {
    return parseFloat(value).toFixed(2);
  }
  return value;
};

const formatNumberWithSpacesAndDecimal = (value) => {
  const numberFormatter = Intl.NumberFormat('en-US');
  const formatted = numberFormatter.format(Number(value).toFixed(2));
  const commaToSpace = formatted.replace(/,/g, ' ');
  const decimalToComma = commaToSpace.replace('.', ',');
  return decimalToComma;
};
const xlsxToJson = (file, sheet = 0) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, {
        type: 'binary',
      });
      const firstSheetName = workbook.SheetNames[sheet];
      const jsonData = XLSX.utils.sheet_to_json(
        workbook.Sheets[firstSheetName]
      );
      jsonData.shift();
      resolve(jsonData);
    };

    reader.onerror = () => reject(reader.error);
  });

const itemListUtils = (t) => {
  return [
    { label: t('units.bespoke'), value: 'units.bespoke' },
    { label: t('units.day'), value: 'units.day' },
    { label: t('units.unit'), value: 'units.unit' },
    { label: t('units.hour'), value: 'units.hour' },
    { label: t('units.kg'), value: 'units.kg' },
    { label: t('units.liter'), value: 'units.liter' },
    { label: t('units.meter'), value: 'units.meter' },
    { label: t('units.square_meter'), value: 'units.square_meter' },
    { label: t('units.cube_meter'), value: 'units.cube_meter' },
    { label: t('units.piece'), value: 'units.piece' },
  ];
};

const parseUnitValue = (value) => {
  switch (value) {
    case 'Bespoke':
    case 'Forfait':
      return 'units.bespoke';
    case 'Unité(s)':
    case 'Unit(s)':
      return 'units.unit';
    case 'Day(s)':
    case 'Jour(s)':
      return 'units.day';
    case 'Heure(s)':
    case 'Hour(s)':
      return 'units.hour';
    case 'Kg':
      return 'units.kg';
    case 'Liter(s)':
      return 'units.liter';
    case 'Mètre(s)':
    case 'Meter(s)':
      return 'units.meter';
    case 'Mètre(s) carré':
    case 'Square Meter(s)':
      return 'units.square_meter';
    case 'Mètre(s) cube':
    case 'Cube Meter(s)':
      return 'units.cube_meter';
    case 'Pièce(s)':
    case 'Piece(s)':
      return 'units.piece';
    default:
      return '';
  }
};

const exportFile = (values, fileName, fileType, fileExtension, bookType) => {
  const ws = utils.json_to_sheet(values);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = write(wb, {
    bookType,
    type: 'array',
  });
  const data = new Blob([excelBuffer], { type: fileType });
  saveAs(data, fileName + fileExtension);
};

const subtractDates = (firstDay, secondDay) => {
  const firstDayUtc = Date.UTC(
    firstDay.getFullYear(),
    firstDay.getMonth(),
    firstDay.getDate()
  );
  const secondDayUtc = Date.UTC(
    secondDay.getFullYear(),
    secondDay.getMonth(),
    secondDay.getDate()
  );
  const day = 1000 * 60 * 60 * 24;
  return (secondDayUtc - firstDayUtc) / day;
};

/**
 * Sort vatRates in ascending order and change display VAT
 * @param {*} vatrates
 * @returns Array
 */
const formatVATRates = (vatrates) => {
  if (!vatrates) return [];
  vatrates?.sort((currentVAT, nextVAT) => currentVAT.value - nextVAT.value);
  return vatrates.map((item) => ({
    ...item,
    display: item.value !== 0 ? `${item.value} %` : item.description,
  }));
};

const getThemeColor = (themeColor) => {
  const regex = /var\((--[\w-]+)\)/;
  const match = themeColor.match(regex);
  if (match) {
    const variableName = match[1];
    const root = document.documentElement;
    const rgbValue = getComputedStyle(root)
      .getPropertyValue(variableName)
      .trim();
    return themeColor.replace(regex, rgbValue);
  }
  return null;
};

const sortUniqueVatRates = (vatRates) => {
  const uniqueRates = Array.from(
    new Map(vatRates.map((rate) => [rate.value, rate])).values()
  );

  return uniqueRates.sort((a, b) => a.value - b.value);
};

const strNumberIsNegative = (number) => {
  return number?.toString()?.includes('-');
};

export {
  groupBy,
  makeid,
  deepValue,
  prettyInvoiceNumber,
  isEquivalent,
  formatNum,
  getFileExtension,
  getObjectMissingFields,
  transformCategoriesJSONintoChristmasTree,
  debounceFn,
  getRcsFromConcatenatdString,
  isEmpty,
  isBlank,
  isValidEmail,
  calculatePercentage,
  roundedNumber,
  formatNumberWithSpacesAndDecimal,
  xlsxToJson,
  itemListUtils,
  parseUnitValue,
  exportFile,
  subtractDates,
  formatVATRates,
  getThemeColor,
  sortUniqueVatRates,
  strNumberIsNegative,
};
